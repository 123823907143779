import React from "react";
import Head from "next/head";
import {
  Box,
  Divider,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FaCalendarTimes, FaMapMarkerAlt, FaStar } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
import { useShopInfoQuery } from "@src/generated/graphql";
import GoogleMap from "@src/components/GoogleMap";
import { routes } from "@src/constants/appRoutes";
import { SHOP_SCREEN_IMG_PLACEHOLDER } from "@src/constants/placeholders";

interface Props {
  shopId: string;
}

export default function ShopInfoBox({ shopId }: Props) {
  const { data, loading } = useShopInfoQuery({ variables: { id: shopId } });
  if (loading || !data) {
    return (
      <>
        <Head>
          <meta property="shopid" content={shopId} />
        </Head>
        <Spinner
          my={3}
          thickness="3px"
          speed="0.65s"
          color="pink.500"
          emptyColor="gray.200"
          size="lg"
        />
      </>
    );
  }
  const shop = data.shopInfo;
  return (
    <>
      <Head>
        <meta key={shop.id} name="shopid" content={shopId} />
      </Head>
      <Box my={3} p={4} pt={2} backgroundColor="gray.50">
        <Box display="flex" alignItems="center">
          <Image
            src={shop.screenImgUrl}
            fallbackSrc={SHOP_SCREEN_IMG_PLACEHOLDER}
            alt={shop.name}
            h={50}
            w={50}
            maxW={50}
            backgroundColor="gray.100"
            objectFit="cover"
            objectPosition="center"
            borderRadius={5}
          />
          <Heading
            as="h4"
            display="flex"
            alignItems="center"
            fontSize="lg"
            ml={2}
            my={2}
            color="pink.500"
          >
            <Link href={routes.shopShow(shop.id)}>
              <a>{shop.name}</a>
            </Link>
          </Heading>
        </Box>
        <Divider />
        <Box my={3}>
          <Text display="flex" alignItems="center" mb={1} fontWeight="bold">
            <FaStar />
            <Text as="span" ml={2}>
              食べログ評価
            </Text>
          </Text>
          {shop.tabelogUrl ? (
            <Link
              href={shop.tabelogUrl}
              display="flex"
              alignItems="center"
              target="_blank"
            >
              {shop.tabelogRating}
              <ExternalLinkIcon ml={1} />
            </Link>
          ) : (
            shop.tabelogUrl
          )}
        </Box>
        <Box my={3}>
          <Text display="flex" alignItems="center" mb={1} fontWeight="bold">
            <FiSun />
            <Text as="span" ml={2}>
              ランチ平均予算
            </Text>
          </Text>
          {shop.lunchAveragePriceMin}円〜{shop.lunchAveragePriceMax}円
        </Box>
        <Box>
          <Text display="flex" alignItems="center" mb={1} fontWeight="bold">
            <FaCalendarTimes />
            <Text as="span" ml={2}>
              定休日
            </Text>
          </Text>
          <div>{shop.closeInfo}</div>
        </Box>
        <Box my={3}>
          <Text display="flex" alignItems="center" mb={1} fontWeight="bold">
            <FaMapMarkerAlt />
            <Text as="span" ml={2}>
              アクセス
            </Text>
          </Text>
          <GoogleMap lat={shop.lat} lng={shop.lng} />
        </Box>
      </Box>
    </>
  );
}
