
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import ShopInfoBox from "@src/components/ShopInfoBox";
import InstaImage from "@src/components/InstaImage";
import QuoteBox from "@src/components/QuoteBox";
import { murakamiMasanori } from "@src/constants/authors";
import { harajukuMokubazaMenuMokubazaMenuTopImage } from "@src/constants/images";
export const meta = {
  path: "/media/harajuku/mokubaza_menu",
  title: "【原宿】mokubazaでメニュー選びに迷ったときはこの選び方！",
  description: "原宿で不動の人気を誇るmokubazaのメニューの選び方を写真付きでご紹介します。メニューやトッピングがたくさんあると、中々選ぶことができない悩みを抱える方も多いのではないでしょうか。そんな方のために、考えられる状況や好みを想定した、おすすめのメニューをご紹介させていただきました。mokubazaに足を運ぶ際には、この記事を参考にしていただければと思います。",
  topImg: harajukuMokubazaMenuMokubazaMenuTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "有名店", "人気店", "お店の紹介", "カレー", "メニュー詳細", "食べログ高評価"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿カレーで連日行列ができる人気店 mokubaza。`}</p>
    <p>{`食べログの評価も高く、百名店にも選出されるなど、評判も味も一級品。`}</p>
    <p>{`そんな mokubaza では 13 種類のカレーメニューがありますが、メニューやトッピングがたくさんあると、中々選ぶことができない悩みを抱える方も多いのではないでしょうか。そんな方のために、考えられる状況や好みを想定した、おすすめのメニューをご紹介させていただきました。mokubaza に足を運ぶ際には、この記事を参考にしていただければと思います。`}</p>
    <h2>{`原宿カレーの名店 mokubaza`}</h2>
    <InstaImage postId="Bpaq5chneZS" mdxType="InstaImage" />
    <InstaImage postId="B_NBPg3DOdl" mdxType="InstaImage" />
    <p>{`ロッジハウスのような落ち着いた外観に佇む原宿カレーの名店 mokubaza。みのりんごと並んで、原宿の二大カレーと呼ばれる名店で、その名店の味を求めて多くの芸能人も来店しているそう。`}</p>
    <p>{`芸術的なキーマカレーはインスタグラムや記事でたくさんみることができるが、その美しさは圧巻の一言。そんな原宿カレーの名店 mokubaza の豊富なメニューを、状況に合わせてご紹介させていただきます。`}</p>
    <h2>{`【本来の味を楽しみたいなら】キーマカレー`}</h2>
    <InstaImage postId="B_JpVmvnHDe" mdxType="InstaImage" />
    <p>{`トッピングもたくさんしたいけど、初めて mokubaza に行くならその店の基本の味をしっかり確かめたい。そんな方も多いのではないでしょうか。`}</p>
    <p>{`このキーマカレーができるまでに費やす時間は`}<strong parentName="p">{`20 時間`}</strong>{`。丹精込めて作られたキーマカレーのおいしさの秘密は、このメニューを食べれば知ることができるでしょう。`}</p>
    <QuoteBox cite="http://www.mokubaza.com/" mdxType="QuoteBox">
  <p>
    キーマカレーは、香り高いホールスパイスと共に10時間以上炒めた玉ねぎ、国産の牛肉、豚肉の様々な部位を独自のブレンドで挽き肉にしたものに、ココナッツミルクと10数種類のスパイス、香味野菜の自家製ブイヨンを加えてじっくり煮込み、合計20時間以上の時間をかけて全て店内で手作りしております。
  </p>
    </QuoteBox>
    <hr></hr>
    <h2>{`【1 番人気なら】チーズキーマカレー`}</h2>
    <InstaImage postId="B_n1iiqAgrH" mdxType="InstaImage" />
    <p>{`「mokubaza」で検索をすると、1 番多く見る写真が`}<strong parentName="p">{`チーズキーマカレー`}</strong>{`ではないでしょうか。`}</p>
    <p>{`キーマカレーにたっぷりとでコーティングしたモッツァレラチーズは、スノードームのように美しい。食べるのがもったいないくらい芸術的で美しいメニューは、食べるのがもったいないくらい。`}</p>
    <p>{`真ん中の卵黄にスプーンを差し込めば、とろとろのモッツァレラチーズ、卵黄、キーマカレーが重なり合う光景を見ることができます。`}</p>
    <p>{`味も文句なしの一品。`}</p>
    <hr></hr>
    <h2>{`【インスタ映えなら】アボカドチーズキーマカレー`}</h2>
    <InstaImage postId="CAmb2EBnPeS" mdxType="InstaImage" />
    <p>{`たっぷりのモッツァレラチーズのコーティングと思いきや、よく見るとうっすらとチーズから浮かび上がってくるものを見つけることができる。`}</p>
    <p>{`このうっすらと浮かび上がるものが、なんと完熟アボカド。一つ一つ丁寧にカットされ、均等な配置で並べられた姿は、先ほどのチーズキーマカレーに負けないくらい芸術的なメニュー。`}</p>
    <p>{`キーマカレーのスパイスと完熟アボカドの甘味がかけ合わさり、それが卵黄とチーズのまろやかさと、いろんな味が口の中で踊り出す。`}</p>
    <p>{`美しさだけでなく、味わいも楽しめる、mokubaza で大人気のメニューです。`}</p>
    <hr></hr>
    <h2>{`【アクセントが欲しいなら】コリアンダーキーマカレー`}</h2>
    <InstaImage postId="B_4fMfkBGY0" mdxType="InstaImage" />
    <p>{`東南アジア料理でよく見かけるコリアンダー(パクチー)を「これでもか！」というくらいに盛り付けた人気メニュー。`}</p>
    <p>{`コリアンダーの華やかな香りが口から鼻に突き抜け、キーマカレーのスパイスと混じり合うその風味はまさに未体験。なのに、どこか懐かしさを思い出すような味わい。`}</p>
    <p>{`記憶に残る忘れないカレーを食べたい方は、コリアンダーキーマカレーのメニューがおすすめです！`}</p>
    <hr></hr>
    <h2>{`【チーズ好きにはたまらない】ダブルチーズキーマカレー`}</h2>
    <InstaImage postId="BqBdUm4Ap_M" mdxType="InstaImage" />
    <p>{`たっぷりのモッツァレラチーズがトッピングされたチーズキーマカレー….かと思いきや、よく見るとチーズの量が少し違うのがお分かりいただけますか！？`}</p>
    <p>{`チーズキーマカレーに、`}<strong parentName="p">{`もう一度`}</strong>{`とろとろのモッツァレラチーズをトッピングしたダブルチーズキーマカレーのメニューです。`}</p>
    <p>{`チーズ好きは黙って見ていられないたっぷりとコーティングされたチーズの断面を、スプーンで割ると、チーズが溶け出す様子が見れます。まろやかなチーズの風味が芳醇なスパイスを包み込み優しい味わいをぜひお楽しみください。`}</p>
    <hr></hr>
    <h2>{`【ガッツリ食べたいなら】焼きエッグキーマカレー`}</h2>
    <InstaImage postId="B1yhTsulVox" mdxType="InstaImage" />
    <p>{`高温のオーブンで焼き上げた香ばしいスパイスの香りが、焦げ目のついたカリッとしたチーズの中に凝縮されたキーマカレー。`}</p>
    <p>{`アツアツのチーズを割ると、程よく熱が通った卵黄とスパイスの効いたカレーが現れます。`}</p>
    <p>{`熱で温められたスパイスとチーズの風味は、通常のメニューとは少し違った味わいを届けてくれる、そんなメニューです。`}</p>
    <p>{`夜のディナータイムでお酒と一緒にいただくと、お酒がすすむのではないでしょうか。`}</p>
    <hr></hr>
    <h2>{`【ヘルシーなら】野菜キーマカレー`}</h2>
    <InstaImage postId="BKVuKnhABgX" mdxType="InstaImage" />
    <p>{`炒めたアスパラ、パプリカ、ミニトマトをトッピングした野菜好きには堪らないベジタブルカレーのメニュー。スパイスの風味が、野菜本来の甘さをしっかり引き出してくれており、食べた瞬間に広がる甘味に驚くのではないでしょうか。`}</p>
    <p>{`どうしても量が物足りないと感じる方は、チーズをトッピングしてみると、さらに違った味わいが楽しめる mokubaza の隠れ人気メニューになります！`}</p>
    <hr></hr>
    <h2>{`【未知のカレーなら】オリジナルトッピング`}</h2>
    <p>{`トッピングの組み合わせによっては、いまだメニューに無いオリジナルのカレーメニューが食べられるかもしれません。
チーズ、アボカド、コリアンダー、ナッツ、たまごをレギュラーメニューと組み合わせることができるので、何回か足を運んだ後にチャレンジしてみるのはいかがでしょうか！？`}</p>
    <hr></hr>
    <h3>{`店舗情報`}</h3>
    <ShopInfoBox shopId="br7847a23akg00ac68hg" mdxType="ShopInfoBox" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;