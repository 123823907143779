import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { FaQuoteLeft } from "react-icons/fa";
import Text from "@src/components/Text";

interface Props {
  cite: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

export default function QuoteBox({ cite, children, style }: Props) {
  return (
    <Box as="blockquote" my={3}>
      <Box
        p={3}
        pb={1}
        borderRadius={5}
        border="solid 3px"
        style={{
          borderImage: "linear-gradient(90deg,#FC8181,#EF3D59) 1",
          ...style,
        }}
      >
        <FaQuoteLeft color="#FC8181" />
        {children}
      </Box>
      <Text as="cite" fontSize="0.6rem">
        引用元: {cite}
      </Text>
    </Box>
  );
}
